import { AuthService } from './auth.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';
import { LoginPage } from './login.page';
import { Router } from '@angular/router';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';
import {map} from 'rxjs/operators';
import { LoginUsuario } from './login-usuario';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(!this.auth.isAuthenticated() && this.tokenService.getToken() != null){
      const usuario = this.login.getUsuario();
      console.log(usuario);
      
      this.auth.login(usuario).subscribe(
        (data) => {
          console.log(data);
          this.tokenService.setToken(data.access_token);
          this.tokenService.setRefreshToken(data.refresh_token);
        },
        (error) => {
          console.error('Request failed with error');
          this.tokenService.logOut();
          this.router.navigate(['login']);
        }
      );
    }
    let authReq = req;
    const token = this.tokenService.getToken();
    if (token != null) {
      authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    }
    return next.handle(authReq);/*.pipe(
      catchError(error => {
        console.log("error");
        let errorMessage = '';
        if (error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          // backend error
          errorMessage = `Server-side error: ${error.status} ${error.message}`;
        }
        
        // aquí podrías agregar código que muestre el error en alguna parte fija de la pantalla.

        return throwError(errorMessage);
      }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            //event = event.clone({body: event.body.data});

            var usuario = new LoginUsuario("soporte@novili.com.co","tenebit1122");

           // this.login.onLogin(usuario);

        }
        //console.log(event);
        return;
        return event;
    })
    );*/
  }

  constructor(private tokenService: TokenService,
              private auth: AuthService,
              private login: LoginPage,
              public router: Router) { }
}

export const interceptorProvider = [{provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}];

