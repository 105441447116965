import { LoginPage } from './login/login.page';
import { ModalPlantillaComunicacionComponent } from './modal-plantilla-comunicacion/modal-plantilla-comunicacion.component';
import { ModalFiltroComponent } from './modal-filtro/modal-filtro.component';
import { ModalDetalleContactoComponent } from './modal-detalle-contacto/modal-detalle-contacto.component';
import { LoginPageModule } from './login/login.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { TableauServerService } from './tableau-server.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

//importamos el interceptor
import { interceptorProvider } from './login/interceptor.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ModalDetalleNegocioComponent } from './modal-detalle-negocio/modal-detalle-negocio.component';
import { ModalDetalleActividadComponent } from './modal-detalle-actividad/modal-detalle-actividad.component';
import { ModalPosponerActividadComponent } from './modal-posponer-actividad/modal-posponer-actividad.component';
import { ModalDetalleClienteComponent } from './modal-detalle-cliente/modal-detalle-cliente.component';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalDetallePotencialComponent } from './modal-detalle-potencial/modal-detalle-potencial.component';
import { PedidosPage } from './pedidos/pedidos.page';

@NgModule({
  declarations: [
    AppComponent,
    ModalDetalleNegocioComponent,
    ModalDetalleActividadComponent,
    ModalPosponerActividadComponent,
    ModalDetalleContactoComponent,
    ModalDetalleClienteComponent,
    ModalDetallePotencialComponent,
    ModalPlantillaComunicacionComponent,
    ModalFiltroComponent
  ],

  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    LoginPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    ReactiveFormsModule,
    IonicSelectableModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    interceptorProvider,
    LoginPage,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TableauServerService,
    Geolocation
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

