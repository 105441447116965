import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableauServerService {

  public apiEndpoint: string;
  public apiEndpointMapa: string;

  constructor(private http: HttpClient) {
    //this.apiEndpoint = "http://localhost:8000";
    //this.apiEndpoint = "https://novili.tenebit.co/index.php?entryPoint=ReportesTableauEntryPoint&reporte=presupuestos_cel";
    this.apiEndpoint = "https://crmtenebit.tenebit.co/index.php?entryPoint=ReportesTableauEntryPointAnon&reporte=presupuestos_cel";
    //this.apiEndpointMapa = "https://novili.tenebit.co/index.php?entryPoint=ReportesTableauEntryPoint&reporte=mapa";
  }

  consultarUrl(informe: string): Observable<any> {
     
    if(informe == "mapa"){
      var url = this.apiEndpointMapa; 
    }else{
      var url = this.apiEndpoint;
    }
    
    return this.http.get(url);
  }
}
