import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { CrmService } from 'src/app/api/crm.service';
import { Reunion } from './../models/reunion.model';
import { Llamada } from '../models/llamada.model';
import { Tarea } from '../models/tarea.model';

@Component({
  selector: 'app-modal-posponer-actividad',
  templateUrl: './modal-posponer-actividad.component.html',
  styleUrls: ['./modal-posponer-actividad.component.scss'],
})
export class ModalPosponerActividadComponent implements OnInit {
  @Input() llamada: Llamada;
  @Input() reunion: Reunion;
  @Input() tarea: Tarea;
  @Input() actividad: string;
  @Input() accion: string;

  constructor(public modalCtrl: ModalController,
              public toastController: ToastController,
              private CrmService: CrmService) {}

  ngOnInit() {}

  cerrarModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  actualizarFechaInicio(fechaInicio: string) {
    switch(this.actividad) {
      case "llamada":
        this.llamada.fecha_hora_inicio = fechaInicio;
      break;
      case "reunion":
        this.reunion.fecha_hora_inicio = fechaInicio;
      break;
    }
  }

  actualizarFechaRealizacion(fechaRealizacion: string) {
    this.tarea.fecha_realizacion = fechaRealizacion;
    this.tarea.fecha_hora_inicio = fechaRealizacion;
    this.tarea.fecha_hora_vencimiento = fechaRealizacion;
  }

  posponerActividad(){
    switch(this.accion) {
      case "posponerLlamada":
        this.posponerLlamada(this.llamada);
      break;
      case "posponerReunion":
        this.posponerReunion(this.reunion);
      break;
      case "posponerTarea":
        this.posponerTarea(this.tarea);
      break;
    }
    this.cerrarModal();
  }

  posponerLlamada(llamada: Llamada){
    this.CrmService.editarLlamada(llamada)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Llamada pospuesta.',
          duration: 2000
        });
        toast.present();
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error. No se pudo posponer la llamada',
          duration: 2000
        });
        toast.present();
      });
  }

  posponerTarea(tarea: Tarea){
    this.CrmService.editarTarea(tarea)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Tarea pospuesta.',
          duration: 2000
        });
        toast.present();
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error. No se pudo posponer la tarea',
          duration: 2000
        });
        toast.present();
      });
  }

  posponerReunion(reunion: Reunion){
    this.CrmService.editarReunion(reunion)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Reunión pospuesta.',
          duration: 2000
        });
        toast.present();
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error. No se pudo posponer la reunión',
          duration: 2000
        });
        toast.present();
      });
  }
}
