export class ProductoComprado {
    constructor(
        public id: string, 
        public nombre: string, 
        public cantidad: number,
        public precio_unitario: number,
        public precio_lista: number,
        public porcentaje_descuento: number,
        public cantidad_descuento: number,
        public porcentaje_iva: number,
        public cantidad_iva: number,
        public ico: number,
        public ad_valorem: number,
        public precio_total: number,
        public cod_producto: string,
        public descripcion: string
    ) {}

    /*constructor(
        public id: string, 
        public nombre: string, 
        public cantidad: number,
        public precio_unitario: number,
        public precio_lista: number,
        public porcentaje_descuento: number,
        public cantidad_descuento: number,
        public porcentaje_iva: number,
        public cantidad_iva: number,
        public precio_total: number
    ) {}*/
}