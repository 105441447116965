export class Potencial {
    constructor(
        public id: string,
        public estado: string,
        public nombre: string,
        public primer_nombre: String,
        public apellido: String,
        public nombre_cuenta: String,
        public canal: string,
        public tipo_documento: string,
        public numero_documento: string,
        public descripcion: string,
        public direccion: string,
        public ciudad: string,
        public departamento: string,
        public pais: string,
        public telefono: string,
        public tipo_cuenta: string,
        public email: string,
        public fecha_creacion: Date,
        public ultima_modificacion: Date,
        public fuente_ingreso: String,
        public etapa_b2b: String,
        public etapa_b2c: String,
        public ubicacion: String,
        public tipo_lead: String,
        public se_entero: String
    ) {}
}
