import { Component,ViewChild, ElementRef } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { TokenService } from './login/token.service';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  
  isLogged = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private tokenService: TokenService,
    private menuController: MenuController
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    if (this.tokenService.getToken()) {
      this.isLogged = true;
      this.menuController.enable(true);
    } else {
      this.isLogged = false;
      this.menuController.enable(false);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  closeMenu() {
    this.menuController.close();
  }

  logOut() {
    this.tokenService.logOut();
    this.menuController.close();
    this.router.navigate(['']);
    window.location.reload();
  }
}
