import { ProductoComprado } from "./productoComprado.model";

export class Pedido {
    constructor(
        public id: string, 
        public nombre: string, 
        public estado_pedido: string,
        public estado_aprobacion: string,
        public estado_factura: string,
        public fecha_realizacion:string,
        public fecha_expiracion: string,
        public id_cliente: string,
        public nombre_cliente: string,
        public id_contacto: string,
        public nombre_contacto: string,
        public direccion: string,
        public ciudad: string,
        public departamento: string,
        public pais: string,
        public descripcion: string,
        public precio_total: number,
        public precio_subtotal: number,
        public total_descuento: number,
        public total_iva: number,
        public total_envio: number,
        public gran_total: number,
        public productosComprados: ProductoComprado[],
        public consecutivo: string,
        public bodega: string
    ) {}
}