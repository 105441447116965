import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-plantilla-comunicacion',
  templateUrl: './modal-plantilla-comunicacion.component.html',
  styleUrls: ['./modal-plantilla-comunicacion.component.scss'],
})
export class ModalPlantillaComunicacionComponent implements OnInit {
  @Input() plantilla: string;
  @Input() contacto: string;

  public plantillaSeleccinada: string;
  
  public valorItem1: string;
  public valorItem2: string;
  public valorItem3: string;


  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  cambiarItem(item, valor){
    switch(item){
      case 'item1':
        this.valorItem1 = valor;
      break;
      case 'item2':
        this.valorItem2 = valor;
      break;
      case 'item3':
        this.valorItem3 = valor;
      break;
    }
  }

  seleccionarPlantilla(plantilla){
    this.plantillaSeleccinada = plantilla;
    this.valorItem1 = "{{1}}";
    this.valorItem2 = "{{2}}";
    this.valorItem3 = "{{3}}";
  }

  abrirWhatsapp(){
    var texto;
    switch(this.plantillaSeleccinada){
      case 'Plantilla1':
        texto = 'Hola ' + this.valorItem1 + ', estamos pendientes de agendar una reunión para revisar el tema ' + this.valorItem2;
      break;
      case 'Plantilla2':
        texto = 'Hola ' + this.valorItem1 + ', estamos pendientes de agendar una llamada para revisar el tema ' + this.valorItem2;
      break;
      default:
        texto = "";
      break;
    }
    window.open('https://wa.me/57' + this.contacto + '?text=' + texto);
    this.cerrarModal();
  }

  abrirCorreo(){
    var texto;
    var asunto;
    switch(this.plantillaSeleccinada){
      case 'Plantilla1':
        asunto = this.valorItem1;
        texto = "Buen día " + this.valorItem2 + ",\n\nEstamos pendientes de agendar una reunión para revisar el tema " + this.valorItem3 + 
        ". Por favor me envias tu disponibilidad para agendar la reunión.\n\nMuchas gracias.\n\nSaludos,\n";
      break;
      default:
        asunto = "Importante";
        texto = "";
      break;
    }
    if(asunto == "{1}}" || asunto == ""){
      asunto = "Importante";
    }
    window.open('mailto:' + this.contacto + '?subject=' + asunto + '!&body=' + texto);
    this.cerrarModal();
  }

  abrirMensajeTexto(){
    var texto;
    switch(this.plantillaSeleccinada){
      case 'Plantilla1':
        texto = 'Hola ' + this.valorItem1 + ', estamos pendientes de agendar una reunión para revisar el tema ' + this.valorItem2;
      break;
      case 'Plantilla2':
        texto = 'Hola ' + this.valorItem1 + ', estamos pendientes de agendar una llamada para revisar el tema ' + this.valorItem2;
      break;
      default:
        texto = "";
      break;
    }
    window.open('sms:' + this.contacto + '?body=' + texto);
    this.cerrarModal();
  }

  cerrarModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
