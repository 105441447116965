import { ProductoComprado } from "./productoComprado.model";

export class Factura {
    constructor(
        public id: string,
        public titulo: string, 
        public numero_factura: string, 
        public fecha_vencimiento: Date,
        public fecha_facturacion: Date, 
        public forma_pago: string, 
        public medio_pago: string, 
        public codigo_vendedor: string, 
        public nombre_vendedor: Date, 
        public estado: string,
        public saldo: string,
        public descripcion: string,
        public nombre_cliente: string,
        public id_cliente: string,
        public pais_cliente: string,
        public depto_cliente: string,
        public ciudad_cliente: string,
        public direccion_cliente: string,
        public precio_total: number,
        public precio_subtotal: number,
        public total_descuento: number,
        public total_iva: number,
        public total_envio: number,
        public gran_total: number,
        public productosComprados: ProductoComprado[],
        public tipo: string
    ) {}
}