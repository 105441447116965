import { Contacto } from './contacto.model';

export class Llamada {
    constructor(
        public id: string,
        public asunto: string, 
        public canal: string,
        public estado: string,
        public direccion: string,
        public resultado: string,
        public fecha_hora_inicio: string, 
        public duracion_horas: number,
        public duracion_minutos: number,
        public parent_type: string,
        public parent_name: string,
        public parent_id: string,
        public contact_name: string,
        public contact_id: string,
        public descripcion: string,
        public contactos: string
    ) {}
}