import { TokenService } from './../login/token.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Cliente } from '../models/cliente.model';
import { CrmService } from 'src/app/api/crm.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-detalle-cliente',
  templateUrl: './modal-detalle-cliente.component.html',
  styleUrls: ['./modal-detalle-cliente.component.scss'],
})
export class ModalDetalleClienteComponent implements OnInit {
  @Input() clientes: Cliente[];
  @Input() cliente: Cliente;
  @Input() accion: string;

  private form: FormGroup;

  public tiposCliente = JSON.parse(this.tokenService.getParametros())['Accounts']['tipo'];
  public tiposDocumento = JSON.parse(this.tokenService.getParametros())['Accounts']['tipoDocumento'];
  public canales = JSON.parse(this.tokenService.getParametros())['Accounts']['canal'];
  
  constructor(public modalCtrl: ModalController,
              public toastController: ToastController,
              private tokenService: TokenService,
              private CrmService: CrmService,
              private formBuilder: FormBuilder) {}

  ngOnInit(){
    this.form = this.formBuilder.group({
      nombre : [this.cliente.nombre],
      tipo : [this.cliente.tipo_cuenta],
      canal : [this.cliente.canal],
      tipo_documento: [this.cliente.tipo_documento],
      numero_documento: [this.cliente.numero_documento],
      telefono : [this.cliente.telefono, Validators.pattern("^(([\+]?[0-9]{1,2}[\s])|([\(][\+]?[0-9]{1,3}[\s]?[0-9]?[\)][\s]))?([0-9]{7}|[0-9]{10}){1}([\s](ext\.)?[\s][0-9]{1,5})?$")],
      email: [this.cliente.email, Validators.email],
      direccion : [this.cliente.direccion],
      ciudad : [this.cliente.ciudad],
      departamento : [this.cliente.departamento],
      pais : [this.cliente.pais],
      descripcion : [this.cliente.descripcion]
    });
  }

  onSubmit(){
    this.cliente.nombre = this.form.value.nombre;
    this.cliente.canal = this.form.value.canal;
    this.cliente.tipo_documento = this.form.value.tipo_documento;
    this.cliente.numero_documento = this.form.value.numero_documento;
    this.cliente.telefono = this.form.value.telefono;
    this.cliente.email = this.form.value.email;
    this.cliente.descripcion = this.form.value.descripcion;
    this.cliente.direccion = this.form.value.direccion;
    this.cliente.ciudad = this.form.value.ciudad;
    this.cliente.departamento = this.form.value.departamento;
    this.cliente.pais = this.form.value.pais;
    this.cliente.tipo_cuenta = this.form.value.tipo;
  }

  crearCliente(){
    this.onSubmit();
    this.CrmService.crearCliente(this.cliente)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha creado el cliente.',
          duration: 2000
        });
        toast.present();

        console.log(response);

        this.cliente.id = response['data']['id'];
        this.clientes.push(this.cliente);
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se creó el cliente',
          duration: 2000
        });
        toast.present();
        location.reload();
      });
    
    this.cerrarModal();
  }

  editarCliente() {
    this.onSubmit();
    this.CrmService.editarCliente(this.cliente)
      .subscribe(async (data)=>{

        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha actilizado el cliente.',
          duration: 2000
        });
        toast.present();
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se actualizó el cliente',
          duration: 2000
        });
        toast.present();
      });
    this.cerrarModal();
  }

  cerrarModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
