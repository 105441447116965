export class Cliente {
    constructor(
        public id: string,
        public nombre: string,
        public canal: string,
        public tipo_documento: string,
        public numero_documento: string,
        public descripcion: string,
        public direccion: string,
        public ciudad: string,
        public departamento: string,
        public pais: string,
        public telefono: string,
        public tipo_cuenta: string,
        public email: string,
        public fecha_creacion: Date,
        public ultima_modificacion: Date,
        public lista_precio: string,
        public cupo: number,
        public cartera: number,
        public plazo: string,
        public tipo_cliente: string
    ) {}
}
