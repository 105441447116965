import { TokenService } from '../login/token.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { CrmService } from 'src/app/api/crm.service';
import { Cliente } from '../models/cliente.model';
//import { Negocio } from '../models/negocio.model';
import { NegociosPorEtapa } from '../models/negocios-etapa.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import * as moment from 'moment';
import 'moment/locale/es';


@Component({
  selector: 'app-modal-detalle-negocio',
  templateUrl: './modal-detalle-negocio.component.html',
  styleUrls: ['./modal-detalle-negocio.component.scss'],
})
export class ModalDetalleNegocioComponent implements OnInit {

  @Input() idCliente: string;
  @Input() clientes: Cliente[];
  //@Input() negocio: Negocio;
  //@Input() negocios: Negocio[];
  @Input() negociosPorEtapas: NegociosPorEtapa[];
  @Input() accion: string;

  public tiposNegocio = JSON.parse(this.tokenService.getParametros())['tiposNegocio'];
  public tomasContacto = JSON.parse(this.tokenService.getParametros())['tomasContacto'];
  public etapasVenta = JSON.parse(this.tokenService.getParametros())['etapasVentaNegocio'];

  public pagConsultaCliente: number = 1;
  public cantPaginasCliente: number;
  
  constructor(public modalCtrl: ModalController,
              public toastController: ToastController,
              private tokenService: TokenService,
              private CrmService: CrmService) {}

  ngOnInit() {}

  async cargarClientes() {
    this.clientes = [];
    do{
      var response = await this.CrmService.consultarClientesPorComercial(this.pagConsultaCliente, 20);
        this.cantPaginasCliente = response['meta']['total-pages'];
        console.log(this.cantPaginasCliente);
        for(var index in response['data']){
            var cliente = response['data'][index];
            //console.log(cliente);
            let now = moment();
            let fecha_modificacion = moment(cliente['attributes']['date_modified']).toDate();
            let semanas = now.diff(fecha_modificacion, "weeks")
            this.clientes.push(
              new Cliente(
                cliente['id'],
                cliente['attributes']['name'],
                cliente['attributes']['canal_c'],
                cliente['attributes']['tipo_documento_c'],
                cliente['attributes']['nit_c'],
                cliente['attributes']['description'],
                cliente['attributes']['billing_address_street'],
                cliente['attributes']['billing_address_city'],
                cliente['attributes']['billing_address_state'],
                cliente['attributes']['billing_address_country'],
                cliente['attributes']['phone_office'],
                cliente['attributes']['account_type'],
                cliente['attributes']['email1'],
                moment(cliente['attributes']['date_entered']).toDate(),
                moment(cliente['attributes']['date_modified']).toDate(),
                cliente['attributes']['aos_product_categories_id_c'],
                cliente['attributes']['cupo_credito_c'],
                cliente['attributes']['saldo_cartera_c'] == null ? 0: cliente['attributes']['saldo_cartera_c'],
                cliente['attributes']['plazo_c'],
                cliente['attributes']['tipo_cliente_c']
              )
            );
          }
      this.pagConsultaCliente += 1;
    }while(this.pagConsultaCliente <= this.cantPaginasCliente)
    
  }

  /*crearNegocio() {
    this.negocio.id_cliente = this.idCliente;
    this.CrmService.crearNegocio(this.negocio)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha creado la oportunidad.',
          duration: 2000
        });
        toast.present();

        this.negocio.id = response['data']['id'];
        this.negocios.push(this.negocio);
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se creó la oportunidad',
          duration: 2000
        });
        toast.present();
      });
    this.cerrarModal();
  }

  editarNegocio() {
    this.negocio.id_cliente = this.idCliente;
    this.CrmService.editarNegocio(this.negocio)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha actualizado la oportunidad.',
          duration: 2000
        });
        toast.present();
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se actualizó la oportunidad',
          duration: 2000
        });
        toast.present();
      });
    this.cerrarModal();
  }

  obtenerEtiquetaEtapaVenta(etapasVenta: JSON, negocio: Negocio){
    for(var index in etapasVenta){
      if(etapasVenta[index].valor == negocio.etapa_venta){
        return etapasVenta[index].etiqueta;
      }
    }
  }

  crearNegocioCliente() {
    this.CrmService.crearNegocio(this.negocio)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha creado la oportunidad.',
          duration: 2000
        });
        toast.present();

        this.negocio.id = response['data']['id'];
        const etiquetaEtapa = this.obtenerEtiquetaEtapaVenta(this.etapasVenta, this.negocio);
        const index = this.negociosPorEtapas.findIndex(negociosPorEtapa => negociosPorEtapa.etapa == etiquetaEtapa);
        this.negociosPorEtapas[index].negocios.push(this.negocio);
        this.negociosPorEtapas[index].nroNegocios += 1;
        this.negociosPorEtapas[index].totalNegocios += Number(this.negocio.monto);
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se creó la oportunidad',
          duration: 2000
        });
        toast.present();
      });
    this.cerrarModal();
  }

  recalcularValorNegocios(negocios: Negocio[]){
    let valorNegocios = 0;
    for(var index in negocios){
      valorNegocios += Number(negocios[index].monto);
    }
    return valorNegocios;
  }

  editarNegocioCliente() {
    this.CrmService.editarNegocio(this.negocio)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha actualizado la oportunidad.',
          duration: 2000
        });
        toast.present();
        const etiquetaEtapa = this.obtenerEtiquetaEtapaVenta(this.etapasVenta, this.negocio);
        const index = this.negociosPorEtapas.findIndex(negociosPorEtapa => negociosPorEtapa.etapa == etiquetaEtapa);
        this.negociosPorEtapas[index].totalNegocios = this.recalcularValorNegocios(this.negociosPorEtapas[index].negocios);
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se actualizó la oportunidad',
          duration: 2000
        });
        toast.present();
      });
    this.cerrarModal();
  }

  clienteChange(event: {
    component: IonicSelectableComponent,
    value: any}) {
    this.actualizarIdCliente(event.value.id);
  }

  cerrarModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  actualizarNombre(nombre: string) {
    this.negocio.nombre = nombre;
  }

  actualizarFechaCierre(fechaCierre: string) {
    this.negocio.fecha_cierre = fechaCierre;
  }

  actualizarMonto(monto: number) {
    this.negocio.monto = monto;
  }

  actualizarTipo(tipo: string) {
    this.negocio.tipo_negocio = tipo;
  }

  actualizarTomaContacto(tomaContacto: string) {
    this.negocio.toma_contacto = tomaContacto;
  }

  actualizarEtapa(etapa: string) {
    this.negocio.etapa_venta = etapa;
  }

  actualizarIdCliente(idCliente: string) {
    this.negocio.id_cliente = idCliente;
  }

  actualizarDescripcion(descripcion: string) {
    this.negocio.descripcion = descripcion;
  }*/

}
