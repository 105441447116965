import { Reunion } from './../models/reunion.model';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Contacto } from '../models/contacto.model';
import { Llamada } from '../models/llamada.model';
import { CrmService } from 'src/app/api/crm.service';
import { Tarea } from '../models/tarea.model';
import { Cliente } from '../models/cliente.model';
import { TokenService } from '../login/token.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-modal-detalle-actividad',
  templateUrl: './modal-detalle-actividad.component.html',
  styleUrls: ['./modal-detalle-actividad.component.scss'],
})
export class ModalDetalleActividadComponent implements OnInit {

  @Input() idCliente: string;
  @Input() clientes: Cliente[];
  @Input() contactos: Contacto[];
  @Input() llamada: Llamada;
  @Input() llamadas: Llamada[];
  @Input() reunion: Reunion;
  @Input() reuniones: Reunion[];
  @Input() tarea: Tarea;
  @Input() tareas: Tarea[];
  @Input() actividad: string;
  @Input() accion: string;

  public subscription: Subscription; 
  public nombresClientes:Cliente [];

  public canalesLlamada = JSON.parse(this.tokenService.getParametros())['Calls']['canal'];
  public estadosLlamada = JSON.parse(this.tokenService.getParametros())['Calls']['estado'];
  public direccionesLlamada = JSON.parse(this.tokenService.getParametros())['Calls']['direccion'];
  public resultadosLlamada = JSON.parse(this.tokenService.getParametros())['Calls']['resultado'];

  public estadosReunion = JSON.parse(this.tokenService.getParametros())['Meetings']['estado'];
  public tiposReunion = JSON.parse(this.tokenService.getParametros())['Meetings']['tipo'];

  public estadosTarea = JSON.parse(this.tokenService.getParametros())['Tasks']['estado'];
  public tiposTarea = JSON.parse(this.tokenService.getParametros())['Tasks']['tipo'];
  public prioridadesTarea = JSON.parse(this.tokenService.getParametros())['Tasks']['prioridad'];

  public contactosSeleccionados;
  
  constructor(public modalCtrl: ModalController,
              public toastController: ToastController,
              private CrmService: CrmService,
              private tokenService: TokenService,
              private geolocation: Geolocation) {}

  ngOnInit() {
    switch(this.accion){
      case "editarLlamada":
        this.contactosActividad("Calls", this.llamada.id);
      break;
      case "editarReunion":
        this.contactosActividad("Meetings", this.reunion.id);
      break;
      case "editarTarea":
        this.contactosActividad("Tasks", this.tarea.id);
      break;
      case "verReunion":
        this.contactos = this.CrmService.consultarContactosPorRelacion("Meetings", this.reunion.id);
        this.contactosActividad("Meetings", this.reunion.id);
      break;
    }
  }

  async contactosActividad(tipoRegistro: string, idRegistro: string) {
    const response = await this.CrmService.consultarContactosActividad(tipoRegistro, idRegistro);

    switch(this.actividad){
      case "llamada":
        this.llamada.contactos = "";
      break;
      case "reunion":
        this.reunion.contactos = "";
      break;
      case "tarea":
        this.tarea.contactos = "";
      break;
    }
    for(var index in response['data']){   
      switch(this.actividad){
        case "llamada":
          this.llamada.contactos += response['data'][index]['id'] + ",";
          this.contactosSeleccionados = this.llamada.contactos.split(",");
        break;
        case "reunion":
          this.reunion.contactos += response['data'][index]['id'] + ",";
          this.contactosSeleccionados = this.reunion.contactos.split(",");
        break;
        case "tarea":
          this.tarea.contactos += response['data'][index]['id'] + ",";
          this.contactosSeleccionados = this.tarea.contactos.split(",");
        break;
      }
    }
  }

  crearLlamada() {
    this.CrmService.crearLlamada(this.llamada)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha creado la llamada.',
          duration: 2000
        });
        toast.present();

        this.llamada.id = response['data']['id'];
        this.llamadas.push(this.llamada);

        for(var index in this.contactosSeleccionados){
          if (this.contactosSeleccionados[index] != ""){
            this.CrmService.crearRelacion('Calls', this.llamada.id, 'Contact', this.contactosSeleccionados[index])
              .subscribe(async (response)=>{
                const toast = await this.toastController.create({
                  color:'success',
                  message: 'Se ha creado la relación llamada con contacto',
                  duration: 2000
                });
                toast.present();
              },
              async (err)=>{
                console.log(err)
                const toast = await this.toastController.create({
                  color:'danger',
                  message: 'Error... no se creó relación llamada con contacto',
                  duration: 2000
                });
                toast.present();
              });
          }
        }
        this.CrmService.agregarUsuarioActividad('Calls', this.llamada.id).subscribe(
          response => {
            console.log("Llamada agregada al calendario");
          },
          err => {
            console.log(err);
          }
        );
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se creó la llamada',
          duration: 2000
        });
        toast.present();
      });

    this.cerrarModal();
  }

  editarLlamada() {
    this.CrmService.editarLlamada(this.llamada)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha actualizado la llamada.',
          duration: 2000
        });
        toast.present();

        for(var index in this.contactosSeleccionados){
          if (this.contactosSeleccionados[index] != ""){
            this.CrmService.crearRelacion('Calls', this.llamada.id, 'Contact', this.contactosSeleccionados[index])
              .subscribe(async (response)=>{
                const toast = await this.toastController.create({
                  color:'success',
                  message: 'Se ha creado la relación llamada con contacto',
                  duration: 2000
                });
                toast.present();
              },
              async (err)=>{
                console.log(err)
                const toast = await this.toastController.create({
                  color:'danger',
                  message: 'Error... no se creó relación llamada con contacto',
                  duration: 2000
                });
                toast.present();
              });
          }
        }
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se actualizó la llamada',
          duration: 2000
        });
        toast.present();
      });

    this.cerrarModal();
  }

  crearReunion() {
    this.CrmService.crearReunion(this.reunion)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha creado la reunión.',
          duration: 2000
        });
        toast.present();

        this.reunion.id = response['data']['id'];
        this.reuniones.push(this.reunion);

        for(var index in this.contactosSeleccionados){
          if (this.contactosSeleccionados[index] != ""){
            this.CrmService.crearRelacion('Meetings', this.reunion.id, 'Contact', this.contactosSeleccionados[index])
              .subscribe(async (response)=>{
                const toast = await this.toastController.create({
                  color:'success',
                  message: 'Se ha creado la relación reunión con contacto',
                  duration: 2000
                });
                toast.present();
              },
              async (err)=>{
                console.log(err)
                const toast = await this.toastController.create({
                  color:'danger',
                  message: 'Error... no se creó relación reunión con contacto',
                  duration: 2000
                });
                toast.present();
              });
          }
        }
        this.CrmService.agregarUsuarioActividad('Meetings', this.reunion.id).subscribe(
          response => {
            console.log("Reunión agregada al calendario");
          },
          err => {
            console.log(err);
          }
        );
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se creó la reunión',
          duration: 2000
        });
        toast.present();
      });

    this.cerrarModal();
  }

  editarReunion() {
    this.CrmService.editarReunion(this.reunion)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha actualizado la reunión.',
          duration: 2000
        });
        toast.present();
        for(var index in this.contactosSeleccionados){
          if (this.contactosSeleccionados[index] != ""){
            this.CrmService.crearRelacion('Meetings', this.reunion.id, 'Contact', this.contactosSeleccionados[index])
              .subscribe(async (response)=>{
                const toast = await this.toastController.create({
                  color:'success',
                  message: 'Se ha creado la relación reunión con contacto',
                  duration: 2000
                });
                toast.present();
              },
              async (err)=>{
                console.log(err)
                const toast = await this.toastController.create({
                  color:'danger',
                  message: 'Error... no se creó relación reunión con contacto',
                  duration: 2000
                });
                toast.present();
              });
          }
        }
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se actualizó la reunión',
          duration: 2000
        });
        toast.present();
      });

    this.cerrarModal();
  }

  crearTarea() {
    this.CrmService.crearTarea(this.tarea)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha creado la tarea.',
          duration: 2000
        });
        toast.present();

        this.tarea.id = response['data']['id'];
        this.tareas.push(this.tarea);

        for(var index in this.contactosSeleccionados){
          
          if (this.contactosSeleccionados[index] != ""){
            this.CrmService.crearRelacion('Tasks', this.tarea.id, 'Contact', this.contactosSeleccionados[index])
              .subscribe(async (response)=>{
                const toast = await this.toastController.create({
                  color:'success',
                  message: 'Se ha creado la relación tarea con contacto',
                  duration: 2000
                });
                toast.present();
              },
              async (err)=>{
                console.log(err)
                const toast = await this.toastController.create({
                  color:'danger',
                  message: 'Error... no se creó relación tarea con contacto',
                  duration: 2000
                });
                toast.present();
              });
          }
        }
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se creó la tarea',
          duration: 2000
        });
        toast.present();
      });

    this.cerrarModal();
  }

  editarTarea() {
    this.CrmService.editarTarea(this.tarea)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha actualizado la tarea.',
          duration: 2000
        });
        
        for(var index in this.contactosSeleccionados){
          
          if (this.contactosSeleccionados[index] != ""){
            this.CrmService.crearRelacion('Tasks', this.tarea.id, 'Contact', this.contactosSeleccionados[index])
              .subscribe(async (response)=>{
                const toast = await this.toastController.create({
                  color:'success',
                  message: 'Se ha creado la relación tarea con contacto',
                  duration: 2000
                });
                toast.present();
              },
              async (err)=>{
                console.log(err)
                const toast = await this.toastController.create({
                  color:'danger',
                  message: 'Error... no se creó relación tarea con contacto',
                  duration: 2000
                });
                toast.present();
              });
          }
        }
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se actualizó la tarea',
          duration: 2000
        });
        toast.present();
      });

    this.cerrarModal();
  }

  crearTareaCliente() {
    this.CrmService.crearTarea(this.tarea)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha creado la tarea.',
          duration: 2000
        });
        toast.present();

        this.tarea.id = response['data']['id'];
        this.tareas.push(this.tarea);

      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se creó la tarea',
          duration: 2000
        });
        toast.present();
      });

    this.cerrarModal();
  }

  editarTareaCliente() {
    this.CrmService.editarTarea(this.tarea)
      .subscribe(async (response)=>{
        const toast = await this.toastController.create({
          color:'success',
          message: 'Se ha actualizado la tarea.',
          duration: 2000
        });
        toast.present();
      },async (err)=>{
        console.log(err)
        const toast = await this.toastController.create({
          color:'danger',
          message: 'Error... no se actualizó la tarea',
          duration: 2000
        });
        toast.present();
      });

    this.cerrarModal();
  }

  cerrarModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  clienteChange(event: {
    component: IonicSelectableComponent,
    value: any}) {
    this.actualizarIdCliente(event.value.id);
  }

  contactoChange(event: {
    component: IonicSelectableComponent,
    value: any}) {
    this.actualizarContacto(event.value);
  }

  actualizarAsunto(asunto: string) {
    switch(this.actividad) {
      case "llamada":
        this.llamada.asunto = asunto;
      break;
      case "reunion":
        this.reunion.asunto = asunto;
      break;
      case "tarea":
        this.tarea.asunto = asunto;
      break;
    }
  }

  actualizarCanal(canal: string) {
    this.llamada.canal = canal;
  }

  actualizarDireccion(direccion: string) {
    this.llamada.direccion = direccion;
  }

  actualizarResultado(resultado: string) {
    this.llamada.resultado = resultado;
  }

  actualizarEstado(estado: string) {
    switch(this.actividad) {
      case "llamada":
        this.llamada.estado = estado;
      break;
      case "reunion":
        this.reunion.estado = estado;
        if (estado == 'Held') {
          this.geolocation.getCurrentPosition().then((resp) => {
            this.reunion.latitud = resp.coords.latitude;
            this.reunion.longitud = resp.coords.longitude;

            console.log("Latitud: " + this.reunion.latitud + " Longitud: " + this.reunion.longitud);
          }).catch((error) => {
            console.log("Error obteniendo localización", error);
          });
        }
      break;
      case "tarea":
        this.tarea.estado = estado;
      break;
    }
  }

  actualizarFechaInicio(fechaInicio: string) {
    switch(this.actividad) {
      case "llamada":
        this.llamada.fecha_hora_inicio = fechaInicio;
      break;
      case "reunion":
        this.reunion.fecha_hora_inicio = fechaInicio;
      break;
      case "tarea":
        this.tarea.fecha_hora_inicio = fechaInicio;
      break;
    }
  }

  actualizarTipo(tipo: string){
    switch(this.actividad) {
      case "tarea":
        this.tarea.tipo = tipo;
      break;
      case "reunion":
        this.reunion.tipo_cita = tipo;
      break;
    }  
  }

  actualizarHorasDuracion(horasDuracion: number) {
    switch(this.actividad) {
      case "llamada":
        this.llamada.duracion_horas = horasDuracion;
      break;
      case "reunion":
        this.reunion.duracion_horas = horasDuracion;
      break;
    }
  }

  actualizarMinutosDuracion(minutosDuracion: number) {
    switch(this.actividad) {
      case "llamada":
        this.llamada.duracion_minutos = minutosDuracion;
      break;
      case "reunion":
        this.reunion.duracion_minutos = minutosDuracion;
      break;
    }
  }

  actualizarContacto(contacto: string) {
    this.contactosSeleccionados = contacto;
    switch(this.actividad) {
      case "llamada":
        this.llamada.contactos = "" + this.contactosSeleccionados;
      break;
      case "reunion":
        this.reunion.contactos = "" + this.contactosSeleccionados;
      break;
      case "tarea":
        this.tarea.contactos = "" + this.contactosSeleccionados;
      break;
    }
  }

  actualizarDescripcion(descripcion: string) {
    switch(this.actividad) {
      case "llamada":
        this.llamada.descripcion = descripcion;
      break;
      case "reunion":
        this.reunion.descripcion = descripcion;
      break;
      case "tarea":
        this.tarea.descripcion = descripcion;
      break;
    }
  }

  actualizarLugar(lugar: string) {
    this.reunion.lugar = lugar;
  }

  actualizarFechaVencimiento(fechaVencimiento: string) {
    this.tarea.fecha_hora_vencimiento = fechaVencimiento;
  }

  actualizarFechaRealizacion(fechaRealizacion: string) {
    this.tarea.fecha_realizacion = fechaRealizacion;
    this.tarea.fecha_hora_inicio = fechaRealizacion;
    this.tarea.fecha_hora_vencimiento = fechaRealizacion;
  }

  actualizarPrioridad(prioridad: string) {
    this.tarea.prioridad = prioridad;
  }

  actualizarIdCliente(idCliente: string) {
    switch(this.actividad){
      case "tarea":
        this.tarea.parent_id = idCliente;
      break;
      case "reunion":
        this.reunion.parent_id = idCliente;
      break;
    }
  }

  buscarCliente(event: {
    component: IonicSelectableComponent,
    text: string 
    }) {
    
      let text = event.text.trim().toLowerCase();   
      event.component.startSearch();
        
      if (!text) {
        // Close any running subscription. 
        if (this.subscription) { 
        this.subscription.unsubscribe()
      }
      
      event.component.items = []; 
      event.component.endSearch();
      
      return;  
    }
 
    this.subscription = this.CrmService.consultarNombresClientes(text).subscribe(response => { 
    // Subscription will be closed when unsubscribed manually.
      if (this.subscription.closed) {
        return;
      }
      
      this.nombresClientes = [];

      var cliente = new Cliente("","","","","","","","","","","","","",new Date(),new Date(),"",0,0,"",""); 
      this.nombresClientes.push(cliente);

      for (const index in response) {    
        this.nombresClientes.push(response[index]);
      }
      
      event.component.items = this.nombresClientes;
      event.component.endSearch();
    }); 
  }

}
