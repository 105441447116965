import { ProductoComprado } from './../models/productoComprado.model';
import { Llamada } from '../models/llamada.model';
import { Contacto } from '../models/contacto.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cliente } from '../models/cliente.model';
import { Reunion } from '../models/reunion.model';
import { Tarea } from '../models/tarea.model';
import { Factura } from '../models/factura.model';
import { Pedido } from 'src/app//models/pedido.model';
import { empty, Observable, throwError } from 'rxjs';

import { JwtModel } from '../models/jwt-model';
import { JwtHelperService } from '@auth0/angular-jwt';

import * as moment from 'moment';
import 'moment/locale/es';
import { Potencial } from '../models/potencial.model';


@Injectable({
  providedIn: 'root'
})
export class CrmService {

  public apiEndpoint: string;
  private apiEnpointBack = 'https://appauth.tenebit.co/';//'https://novili.tenebit.co/services/novili-integracion/public/'
  url_novili = 'https://novili.tenebit.co/services/novili-integracion/public/';

  constructor(private http: HttpClient,
              public jwtHelper: JwtHelperService) { 
    this.apiEndpoint = window.localStorage.getItem("BaseUri") + '/Api/V8/';
  }

  /*********Operaciones clientes**************/
  async consultarClientesPorComercial(pagConsulta: number, pagSize: number){ //TODO: Recibir los filtros desde el botón de filtro
    //const url = this.apiEndpoint + 'module/Accounts?filter[operator]=and&filter[assigned_user_id][eq]=' + window.sessionStorage.getItem('UserID');
    var url: string;
    
    //pagSize = 20;
    if(pagSize <= 20){
      url = this.apiEndpoint + 'module/Accounts?sort=-date_modified&page[size]=' + pagSize + '&page[number]=' + pagConsulta;
    }else{
      url = this.apiEndpoint + 'module/Accounts?page[size]=' + pagSize + '&page[number]=' + pagConsulta;
    }
    
    return this.http.get(url).toPromise();
  }

  consultarNombresClientes(nombre, listasPrecio=false, id_cliente=""){ //TODO: Recibir los filtros desde el botón de filtro
    
    const cabecera = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    
    var parametros = {
      "campos":"id, numero_documento_c, CASE name WHEN 'SIN-NOMBRE' THEN CONCAT('(SIN-NOMBRE) ', numero_documento_c) ELSE name END name, aos_product_categories_id_c, cupo_credito_c, saldo_cartera_c, plazo_c, tipo_cliente_c",
      "tabla":"accounts",
      "condicion": "",
      "clausula": "LIMIT 20",
      "database": "novili_suitecrm"
    }

    if(sessionStorage.getItem('UserRol') == "Vendedor"){//Validar el rol del usuario
      parametros['condicion'] = "assigned_user_id = '" + sessionStorage.getItem('UserID') + "'";
    }else{
      parametros['condicion'] = "1 = 1";
    }

    if(listasPrecio){//Validar listas de precios activas
      const listasActivas = JSON.parse(localStorage.getItem('Parametros'))['listasPreciosActivas'];
      var listas = "";
      listasActivas.forEach(listaActiva => {
        if(listas != "") listas += ",";
        listas += "'" + listaActiva.id + "'";
      });
      parametros.condicion += " AND aos_product_categories_id_c IN (" + listas + ")"; 
    }

    if(nombre.indexOf(":") != "-1"){
      var numero_documento = nombre.split(":", 2)[1];
      var nombre = nombre.split(":", 2)[0];

      if(numero_documento != "")
        parametros['condicion'] += " AND numero_documento_c LIKE '" +  numero_documento + "%'";
    }
    
    if(nombre != "")
      parametros['condicion'] += "  AND name LIKE '%" + nombre + "%'";

    if(id_cliente != "")
      parametros['condicion'] += "  AND accounts.id = '" + id_cliente + "'";
    
    return this.http.post(this.apiEnpointBack + "consultaMySQL", parametros , cabecera);

  }

  getInventario(data){
    let header = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    let url = this.url_novili + "getInventario";

    return this.http.post(url, data , header);
  }

  getProductos(data){
    let header = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    let url = this.url_novili + "getProductos";

    return this.http.post(url, data , header);
  }

  crearCliente(cliente: Cliente) {
    const tipoRegistro = "Accounts";
    const atributos = this.obtenerAtributosCliente(cliente);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarCliente(cliente: Cliente) {
    const atributos = this.obtenerAtributosCliente(cliente);
    return this.actualizarRegistro("Accounts", cliente.id, atributos);
  }

  obtenerAtributosCliente(cliente: Cliente) {
    const atributos = {
      "name": cliente.nombre,
      "canal_c": cliente.canal,
      "tipo_documento_c": cliente.tipo_documento,
      "numero_documento_c": cliente.numero_documento,
      "tipo_c": cliente.tipo_cuenta,
      "description": cliente.descripcion,
      "billing_address_street": cliente.direccion,
      "billing_address_city": cliente.ciudad,
      "billing_address_state": cliente.departamento,
      "billing_address_country": cliente.pais,
      "phone_office": cliente.telefono,
      "email1": cliente.email,
    };

    return atributos;
  }

  async filtrarClientes(nroPagina: number, filtroCliente)
  { 
    var filtro = "&filter[operator]=and";
    var paginacion = '&page[size]=20&page[number]=' + nroPagina;
    
    if (filtroCliente.id != "") {
      filtro = filtro + "&filter[id][eq]=" + filtroCliente.id;
    }

    if (filtroCliente.nombre != "") {
      filtro = filtro + "&filter[name][eq]=" + filtroCliente.nombre;
    }
    if (filtroCliente.tipo != "") {
      filtro = filtro + "&filter[tipo_c][eq]=" + filtroCliente.tipo;
    }
    if (filtroCliente.canal != "") {
      filtro = filtro + "&filter[canal_c][eq]=" + filtroCliente.canal;
    }

    if (filtroCliente.telefono != "") {
      filtro = filtro + "&filter[phone_office][eq]=" + filtroCliente.telefono;
    }
    
    if (filtroCliente.numero_documento != "") {
      filtro = filtro + "&filter[numero_documento_c][eq]=" + filtroCliente.numero_documento;
    }

    if (filtroCliente.tipo_documento != "") {
      filtro = filtro + "&filter[tipo_documento_c][eq]=" + filtroCliente.tipo_documento;
    }

    /*if (filtroCliente.correo != "") {
      filtro = filtro + "&filter[email1][eq]=" + filtroCliente.correo;
    }
    if (filtroCliente.ciudad != "") {
      filtro = filtro + "&filter[billing_address_city][eq]=" + filtroCliente.ciudad;
    }
    if (filtroCliente.departamento != "") {
      filtro = filtro + "&filter[billing_address_state][eq]=" + filtroCliente.departamento;
    }
    if (filtroCliente.pais != "") {
      filtro = filtro + "&filter[billing_address_country][eq]=" + filtroCliente.pais;
    }*/
    
    filtro += "&filter[deleted][eq]=0";

    const url = this.apiEndpoint + 'module/Accounts?sort=-date_modified'  + filtro + paginacion;
    return this.http.get(url).toPromise();
  }

  /*********Operaciones clientes potenciales**************/
  async consultarClientesPotenciales(pagConsulta: number, pagSize: number){ //TODO: Recibir los filtros desde el botón de filtro
    //const url = this.apiEndpoint + 'module/Accounts?filter[operator]=and&filter[assigned_user_id][eq]=' + window.sessionStorage.getItem('UserID');
    var url: string;
    
    pagSize = 20;
    if(pagSize <= 20){
      url = this.apiEndpoint + 'module/Leads?sort=-date_modified&page[size]=' + pagSize + '&page[number]=' + pagConsulta;
    }else{
      url = this.apiEndpoint + 'module/Leads?page[size]=' + pagSize + '&page[number]=' + pagConsulta;
    }
    
    return this.http.get(url).toPromise();
  }

  consultarNombresPotenciales(nombre){ //TODO: Recibir los filtros desde el botón de filtro
    
    const cabecera = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    
    var parametros = {
      "campos":"id, UPPER(CONCAT(first_name, ' ', last_name,' - ',  numero_documento_c)) name",
      "tabla":"leads",
      "condicion": "",
      "clausula": "LIMIT 20",
      "database": "novili_suitecrm"
    }

    if(sessionStorage.getItem('UserRol') == "Vendedor"){//Validar el rol del usuario
      parametros['condicion'] = "assigned_user_id = '" + sessionStorage.getItem('UserID') + "'";
    }else{
      parametros['condicion'] = "1 = 1";
    }
    
    if(nombre.indexOf(":") != "-1"){
      var numero_documento = nombre.split(":", 2)[1];
      var nombre = nombre.split(":", 2)[0];

      if(numero_documento != "")
        parametros['condicion'] += " AND numero_documento_c LIKE '" +  numero_documento + "%'";
    }
    
    if(nombre != "")
      parametros['condicion'] += "  AND CONCAT(first_name, ' ', last_name ) LIKE '%" + nombre + "%'";
      
    return this.http.post(this.apiEnpointBack + "consultaMySQL", parametros , cabecera);
     
  }

  async filtrarPotenciales(nroPagina: number, filtroCliente)
  { 
    var filtro = "&filter[operator]=and";
    var paginacion = '&page[size]=20&page[number]=' + nroPagina;
    
    if (filtroCliente.id != "") {
      filtro = filtro + "&filter[id][eq]=" + filtroCliente.id;
    }

    if (filtroCliente.nombre != "") {
      filtro = filtro + "&filter[name][eq]=" + filtroCliente.nombre;
    }
    if (filtroCliente.estado != "") {
      filtro = filtro + "&filter[status][eq]=" + filtroCliente.tipo;
    }
    if (filtroCliente.canal != "") {
      filtro = filtro + "&filter[canal_c][eq]=" + filtroCliente.canal;
    }

    if (filtroCliente.fuente_ingreso != "") {
      filtro = filtro + "&filter[fuente_ingreso_c][eq]=" + filtroCliente.fuente_ingreso;
    }
    
    if (filtroCliente.numero_documento != "") {
      filtro = filtro + "&filter[numero_documento_c][eq]=" + filtroCliente.numero_documento;
    }

    if (filtroCliente.tipo_documento != "") {
      filtro = filtro + "&filter[tipo_documento_c][eq]=" + filtroCliente.tipo_documento;
    }

    if (filtroCliente.tipo != "") {
      filtro = filtro + "&filter[tipo_lead_c][eq]=" + filtroCliente.tipo;
    }
    
    filtro += "&filter[deleted][eq]=0";

    const url = this.apiEndpoint + 'module/Leads?sort=-date_modified'  + filtro + paginacion;
    return this.http.get(url).toPromise();
  }

  crearPotencial(cliente: Potencial) {
    const tipoRegistro = "Leads";
    const atributos = this.obtenerAtributosPotencial(cliente);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarPotencial(cliente: Potencial) {
    const atributos = this.obtenerAtributosPotencial(cliente);
    return this.actualizarRegistro("Leads", cliente.id, atributos);
  }

  /*potencial['id'],
          potencial['attributes']['status'],
          potencial['attributes']['account_name'],
          potencial['attributes']['first_name'],
          potencial['attributes']['last_name'],
          potencial['attributes']['canal_c'],
          potencial['attributes']['tipo_documento_c'],
          potencial['attributes']['numero_documento_c'],
          potencial['attributes']['description'],
          potencial['attributes']['primary_address_street'],
          potencial['attributes']['primary_address_city'],
          potencial['attributes']['primary_address_state'],
          potencial['attributes']['primary_address_country'],
          potencial['attributes']['phone_mobile'],
          potencial['attributes']['tipo_c'],
          potencial['attributes']['email1'],
          moment(potencial['attributes']['date_entered']).toDate(),
          moment(potencial['attributes']['date_modified']).toDate(),
          potencial['attributes']['fuente_ingreso_c'],
          potencial['attributes']['etapas_b2b_1_c'],
          potencial['attributes']['etapas_b2c_1_c'],
          potencial['attributes']['ubicacion_c'],
          potencial['attributes']['tipo_lead_c'],
          potencial['attributes']['como_se_entero_c']*/

  obtenerAtributosPotencial(cliente: Potencial) {
    const atributos = {
      "first_name": cliente.primer_nombre,
      "last_name": cliente.apellido,
      "account_name": cliente.nombre_cuenta,
      "canal_c": cliente.canal,
      "tipo_documento_c": cliente.tipo_documento,
      "numero_documento_c": cliente.numero_documento,
      "tipo_de_cliente_c": cliente.tipo_cuenta,
      "description": cliente.descripcion,
      "primary_address_street": cliente.direccion,
      "primary_address_city": cliente.ciudad,
      "primary_address_state": cliente.departamento,
      "primary_address_country": cliente.pais,
      "phone_mobile": cliente.telefono,
      "email1": cliente.email,
      "ubicacion_c": cliente.ubicacion,
      "fuente_ingreso_c": cliente.fuente_ingreso,
      "como_se_entero_c": cliente.se_entero,
      "tipo_lead_c": cliente.tipo_lead
    };

    return atributos;
  }

  /******************Consultas modulos relacionados***********/
  consultarFacturasPorRelacion(tipoRegistro: string, idRegistro: string): Factura[] {
    const url = this.apiEndpoint + 'module/' + tipoRegistro + '/' + idRegistro + '/relationships/aos_invoices?sort=-due_date';
    var facturas: Factura[] = [];
    //var negocio: Object = new Object();
    this.http.get(url).subscribe(async (response) => {
      for(var index in response['data']){
        const id = response['data'][index]['id'];
        //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
        const factura = await this.consultarRegistroID('AOS_Invoices', id);
        console.log(factura);
        
        facturas.push(
          new Factura(
            factura['data']['id'],
            factura['data']['attributes']['name'],
            factura['data']['attributes']['nro_factura_c'],
            factura['data']['attributes']['due_date'],
            factura['data']['attributes']['invoice_date'],
            factura['data']['attributes']['forma_pago_c'],
            factura['data']['attributes']['medio_pago_c'],
            factura['data']['attributes']['cod_vendedor_c'],
            factura['data']['attributes']['nombre_vendedor_c'],
            factura['data']['attributes']['status'],
            factura['data']['attributes']['saldo_cartera_c'],
            factura['data']['attributes']['description'],
            factura['data']['attributes']['billing_account'],
            factura['data']['attributes']['billing_account_id'],
            factura['data']['attributes']['billing_address_country'],
            factura['data']['attributes']['billing_address_state'],
            factura['data']['attributes']['billing_address_city'],
            factura['data']['attributes']['billing_address_street'],
            factura['data']['attributes']['total_amt'],
            factura['data']['attributes']['subtotal_amount'],
            factura['data']['attributes']['discount_amount'],
            factura['data']['attributes']['tax_amount'],
            factura['data']['attributes']['shipping_tax_amt'],
            factura['data']['attributes']['total_amount'],
            [],
            factura['attributes']['tipo_documento_c']        
          )
        );
      }
    });

    /*tarea['data']['attributes']['parent_type'],
            tarea['data']['attributes']['parent_name'],
            tarea['data']['attributes']['parent_id'],*/

    return facturas;
  }

  consultarContactosPorRelacion(tipoRegistro: string, idRegistro: string): Contacto[] {
    const url = this.apiEndpoint + 'module/' + tipoRegistro + '/' + idRegistro + '/relationships/contacts';
    var contactos: Contacto[] = [];
    //var negocio: Object = new Object();
    this.http.get(url).subscribe(async (response) => {
      for(var index in response['data']){
        const id = response['data'][index]['id'];
        //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
        const contacto = await this.consultarRegistroID('Contacts', id);
        contactos.push(
          new Contacto(
            contacto['data']['id'],
            contacto['data']['attributes']['first_name'],
            contacto['data']['attributes']['last_name'],
            contacto['data']['attributes']['first_name'] + " " + contacto['data']['attributes']['last_name'],
            contacto['data']['attributes']['tipo_documento_c'],
            contacto['data']['attributes']['numero_documento_c'],
            contacto['data']['attributes']['title'],
            contacto['data']['attributes']['description'],
            contacto['data']['attributes']['primary_address_street'],
            contacto['data']['attributes']['primary_address_city'],
            contacto['data']['attributes']['primary_address_state'],
            contacto['data']['attributes']['primary_address_country'],
            contacto['data']['attributes']['phone_mobile'],
            contacto['data']['attributes']['phone_office'],
            contacto['data']['attributes']['email1'],
            moment(contacto['data']['attributes']['date_entered']).toDate(),
            moment(contacto['data']['attributes']['date_modified']).toDate()
          )
        );
      }
    });
    return contactos;
  }

  consultarTareasPorRelacion(tipoRegistro: string, idRegistro: string): Tarea[] {
    const url = this.apiEndpoint + 'module/' + tipoRegistro + '/' + idRegistro + '/relationships/tasks';
    var tareas: Tarea[] = [];
    //var negocio: Object = new Object();
    this.http.get(url).subscribe(async (response) => {
      for(var index in response['data']){

        try{
          const id = response['data'][index]['id'];
          //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
          const tarea = await this.consultarRegistroID('Tasks', id);
          var fecha = new Date(tarea['data']['attributes']['date_start']);
          tareas.push(
            new Tarea(
              tarea['data']['id'],
              tarea['data']['attributes']['name'],
              tarea['data']['attributes']['status'],
              tarea['data']['attributes']['tipo_tarea_c'],
              moment(fecha.setHours(fecha.getHours() - 5)).toString(),
              tarea['data']['attributes']['date_due'],
              tarea['data']['attributes']['date_due'],
              tarea['data']['attributes']['parent_type'],
              tarea['data']['attributes']['parent_name'],
              tarea['data']['attributes']['parent_id'],
              tarea['data']['attributes']['contact_name'],
              tarea['data']['attributes']['contact_id'],
              tarea['data']['attributes']['priority'],
              tarea['data']['attributes']['description'],
              ""
            )
          );  
        }catch(error){}
      }
    });
    return tareas;
  }

  consultarLlamadasPorRelacion(tipoRegistro: string, idRegistro: string): Llamada[] {
    const url = this.apiEndpoint + 'module/' + tipoRegistro + '/' + idRegistro + '/relationships/calls';
    var llamadas: Llamada[] = [];
    this.http.get(url).subscribe(async (response) => {
      
      for(var index in response['data']){
        const id = response['data'][index]['id'];
        //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
        try{
          const llamada = await this.consultarRegistroID('Calls', id);
          var fecha = new Date(llamada['data']['attributes']['date_start']);
          llamadas.push(
            new Llamada(
              llamada['data']['id'],
              llamada['data']['attributes']['name'],
              llamada['data']['attributes']['canal_c'],
              llamada['data']['attributes']['status'],
              llamada['data']['attributes']['direction'],
              llamada['data']['attributes']['resultado_c'],
              moment(fecha.setHours(fecha.getHours() - 5)).toString(),
              llamada['data']['attributes']['duration_hours'],
              llamada['data']['attributes']['duration_minutes'],
              llamada['data']['attributes']['parent_type'],
              llamada['data']['attributes']['parent_name'],
              llamada['data']['attributes']['parent_id'],
              llamada['data']['attributes']['contact_name'],
              llamada['data']['attributes']['contact_id'],
              llamada['data']['attributes']['description'],
              ""
            )
          );
        }catch(error){

        }
        
      }
    });
    return llamadas;
  }

  consultarReunionesPorRelacion(tipoRegistro: string, idRegistro: string): Reunion[] {
    const url = this.apiEndpoint + 'module/' + tipoRegistro + '/' + idRegistro + '/relationships/meetings';
    var reuniones: Reunion[] = [];
    this.http.get(url).subscribe(async (response) => {
      
      for(var index in response['data']){

        try{
          const id = response['data'][index]['id'];
          const reunion =  await this.consultarRegistroID('Meetings', id);
          var fecha = new Date(reunion['data']['attributes']['date_start']);
          reuniones.push(
            new Reunion(
              reunion['data']['id'],
              reunion['data']['attributes']['name'],
              reunion['data']['attributes']['status'],
              reunion['data']['attributes']['tipo_cita_c'],
              moment(fecha.setHours(fecha.getHours() - 5)).toString(),
              reunion['data']['attributes']['duration_hours'],
              reunion['data']['attributes']['duration_minutes'],
              reunion['data']['attributes']['parent_type'],
              reunion['data']['attributes']['parent_name'],
              reunion['data']['attributes']['parent_id'],
              reunion['data']['attributes']['contact_name'],
              reunion['data']['attributes']['contact_id'],
              reunion['data']['attributes']['location'],
              reunion['data']['attributes']['description'],
              reunion['data']['attributes']['jjwg_maps_lat_c'],
              reunion['data']['attributes']['jjwg_maps_lng_c'],
              ""
            )
          );
        }catch(error){}
      }
    });
    return reuniones;
  }

  async consultarVendedor(id_vendedor){ //TODO: Recibir los filtros desde el botón de filtro
    //const url = this.apiEndpoint + 'module/Accounts?filter[operator]=and&filter[assigned_user_id][eq]=' + window.sessionStorage.getItem('UserID');
    var url: string;
    url = this.apiEndpoint + 'module/Users/' + id_vendedor;
    
    return this.http.get(url).toPromise();
  }


/**********Operaciones Calls*********** */
  crearLlamada(llamada: Llamada) {
    const tipoRegistro = "Calls";
    const atributos = this.obtenerAtributosLlamada(llamada);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarLlamada(llamada: Llamada) {
    const tipoRegistro = "Calls";
    const atributos = this.obtenerAtributosLlamada(llamada);
    return this.actualizarRegistro(tipoRegistro, llamada.id, atributos);
  }

  obtenerAtributosLlamada(llamada: Llamada) {
    var fecha = new Date(llamada.fecha_hora_inicio);

    const atributos = {
      "name": llamada.asunto,
      "canal_c": llamada.canal,
      "status": llamada.estado,
      "direction": llamada.direccion,
      "resultado_c": llamada.resultado,
      "date_start": moment(fecha.setHours(fecha.getHours() + 5)).format('YYYY-MM-DD HH:mm:ss').toString(),
      "duration_hours": llamada.duracion_horas,
      "duration_minutes": llamada.duracion_minutos,
      "description": llamada.descripcion,
      "parent_type": llamada.parent_type,
      "parent_name": llamada.parent_name,
      "parent_id": llamada.parent_id,
      "contact_name": llamada.contact_name,
      "contact_id": llamada.contact_id
    };
    return atributos;
  }

  /*****************Operaciones pedidos (cotizaciones)**************** */
  consultarPedidosPorRelacion(tipoRegistro: string, idRegistro: string): Pedido[] {
    const url = this.apiEndpoint + 'module/' + tipoRegistro + '/' + idRegistro + '/relationships/aos_quotes';
    var pedidos: Pedido[] = [];
    
    this.http.get(url).subscribe(async (response) => {
      for(var index in response['data']){
        const id = response['data'][index]['id'];
        const pedido = (await this.consultarRegistroID('AOS_Quotes', id))['data'];
        var productosComprados: ProductoComprado[] = await this.consultarProductosCompradosPorPedido(pedido['id'], 'AOS_Quotes');
        
        pedidos.push(
          new Pedido(
            pedido['id'],
            pedido['attributes']['name'],
            pedido['attributes']['stage'],
            pedido['attributes']['approval_status'],
            pedido['attributes']['invoice_status'],
            pedido['attributes']['fecha_realizacion_c'],
            pedido['attributes']['expiration'],
            pedido['attributes']['billing_account_id'],
            pedido['attributes']['billing_account'],
            pedido['attributes']['billing_contact_id'],
            pedido['attributes']['billing_contact'],
            pedido['attributes']['billing_address_street'],
            pedido['attributes']['billing_address_city'],
            pedido['attributes']['billing_address_state'],
            pedido['attributes']['billing_address_country'],
            pedido['attributes']['description'],
            Number(pedido['attributes']['total_amt']),
            Number(pedido['attributes']['subtotal_amount']),
            Number(pedido['attributes']['discount_amount']),
            Number(pedido['attributes']['tax_amount']),
            Number(pedido['attributes']['shipping_amount']),
            Number(pedido['attributes']['total_amount']),
            productosComprados,
            pedido['attributes']['consecutivo_pedido_c'],
            pedido['attributes']['bodega_c'],
          )
        );
      }
    });

    return pedidos;
  }

  filtrarPedidos(pagSize: number, pagConsulta: number, filtroPedido){
    var filtro = "&filter[operator]=and";
    
    if(filtroPedido.id_cliente != "") {
      filtro += "&filter[billing_account_id][eq]=" + filtroPedido.id_cliente;
    }

    /*if(filtroPedido.nombre_cliente != "") {
      filtro += "&filter[billing_account][eq]=" + filtroPedido.nombre_cliente;
    }*/

    if(filtroPedido.etapa != "") {
      filtro += "&filter[stage][eq]=" + filtroPedido.etapa;
    }
    if(filtroPedido.numero != "") {
      filtro += "&filter[number][eq]=" + filtroPedido.numero;
    }

    if(filtroPedido.fecha_fin != "") {
      filtro += "&filter[date_modified][lt]=" + filtroPedido.fecha_fin;
    }

    if(filtroPedido.fecha_inicio != "") {
      filtro += "&filter[date_modified][gt]=" + filtroPedido.fecha_inicio;
    }
    
    const url = this.apiEndpoint + 'module/AOS_Quotes?page[size]=' + pagSize + '&page[number]=' + pagConsulta + filtro;
    return this.http.get(url).toPromise();
  }

  //obtener los productos asociados en una cotización
  async consultarProductosCompradosPorPedido(id: string, relacion: String){
    const url = this.apiEndpoint + 'module/' + relacion + '/' + id + '/relationships/aos_products_quotes';
    //const url = this.apiEndpoint + 'module/AOS_Invoices/' + id + '/relationships/aos_products_quotes';
    
    var arrProductosComprados: ProductoComprado[] = [];

    this.http.get(url).subscribe(async (response) => {

      for(var index in response['data']){
        const id = response['data'][index]['id'];
        const productoComprado = (await this.consultarRegistroID('AOS_Products_Quotes', id))['data'];
        
        arrProductosComprados.push(
          new ProductoComprado(
            productoComprado['id'],
            productoComprado['attributes']['name'],
            Number(productoComprado['attributes']['product_qty']),
            Number(productoComprado['attributes']['product_unit_price']),
            Number(productoComprado['attributes']['product_list_price']),
            Number(productoComprado['attributes']['product_discount']),
            Number(productoComprado['attributes']['product_discount_amount']),
            Number(productoComprado['attributes']['vat']),
            Number(productoComprado['attributes']['vat_amt']),
            Number(productoComprado['attributes']['ico_c']),
            Number(productoComprado['attributes']['advaloren_c']),
            Number(productoComprado['attributes']['product_total_price']),
            productoComprado['attributes']['part_number'],
            productoComprado['attributes']['description']
          )
        );
      }
      
    });
    return arrProductosComprados;
  }

  async crearPedido(pedido: Pedido){
    const tipoRegistro = "AOS_Quotes";
    const atributos = this.obtenerAtributosPedido(pedido);
    return this.crearRegistro(tipoRegistro, atributos).toPromise();
  }

  obtenerAtributosPedido(pedido: Pedido){
    console.log(pedido);
    const atributos = {
      "name": pedido.nombre,
      "stage": pedido.estado_pedido,
      "approval_status": pedido.estado_aprobacion,
      "invoice_status": pedido.estado_factura,
      "fecha_realizacion_c": moment(pedido.fecha_realizacion).format('YYYY-MM-DD').toString(),
      "expiration": moment(pedido.fecha_expiracion).format('YYYY-MM-DD').toString(),
      "billing_account_id": pedido.id_cliente,
      "billing_contact_id": pedido.id_contacto,
      "billing_address_street": pedido.direccion,
      "billing_address_city": pedido.ciudad,
      "billing_address_state": pedido.departamento,
      "billing_address_country": pedido.pais,
      "description": pedido.descripcion,
      "total_amt": pedido.precio_total,
      "subtotal_amount": pedido.precio_subtotal,
      "discount_amount": pedido.total_descuento,
      "tax_amount": pedido.total_iva,
      "shipping_amount": pedido.total_envio,
      "total_amount": pedido.gran_total,
      "billing_account": pedido.nombre_cliente,
      "consecutivo_pedido_c" : pedido.consecutivo,
      "bodega_c" : pedido.bodega
    };
    return atributos;
  }

  eliminarProductoPedido(productoComprado: ProductoComprado){
    if (productoComprado.id != ""){
      console.log("Eliminar producto ", productoComprado.id);
      this.eliminarRegistro("AOS_Products_Quotes", productoComprado.id)
        .subscribe(async (response)=>{
          console.log("Producto eliminado");
      },
      async (err)=>{
        console.log("Error eliminando producto");
      });
    }
  }

  async actualizarPedido(pedido: Pedido){
    console.log(pedido);
    const tipoRegistro = "AOS_Quotes";
    const atributos = this.obtenerAtributosPedido(pedido);
    return this.actualizarRegistro(tipoRegistro, pedido.id, atributos).toPromise();
  }

  async enviarPedido(pedido: Pedido, cliente: Cliente){
    
    var factura = {
      "nit" : cliente.numero_documento,
      "nota": pedido.descripcion,
      //"nit" : 0,
      "movimientos":[]
    }
    
    pedido['productosComprados'].forEach(element => {
      console.log(pedido);
      var producto = {
        "producto":element['cod_producto'],
        "valorunit":element['precio_lista'],
        "cantidad":element['cantidad'],
        "bodega": pedido['bodega'],
        "descuento": Math.round(element['porcentaje_descuento'])
      };

      factura['movimientos'].push(producto)
    });

    //Llamar funcion***********         
    const cabecera = {headers: new HttpHeaders({'Content-Type': 'application/json'})};            
    let url = this.url_novili + "enviarPedido";
    return this.http.post<any>(url, factura, cabecera);
  }

  /*******************Operaciones facturas************* */
  consultarFacturas(pagConsulta: number, pagSize: number){ //TODO: Recibir los filtros desde el botón de filtro
    //const url = this.apiEndpoint + 'module/Accounts?filter[operator]=and&filter[assigned_user_id][eq]=' + window.sessionStorage.getItem('UserID');
    const url = this.apiEndpoint + 'module/AOS_Invoices?sort=-due_date&page[size]=' + pagSize + '&page[number]=' + pagConsulta;
    return this.http.get(url).toPromise();
  }

  filtrarFacturas(pagSize: number, pagConsulta: number, filtroFactura){
    var filtro = "&filter[operator]=and";
    
    if(filtroFactura.id_cliente != "") {
      filtro += "&filter[billing_account_id][eq]=" + filtroFactura.id_cliente;
    }
    if(filtroFactura.numero != "") {
      filtro += "&filter[name][eq]=FACTURA NOBLE VINOS Y LICORES Nº" + filtroFactura.numero;
    }

    if(filtroFactura.estado != "") {
      if(filtroFactura.estado == "Pagadas"){
        filtro += "&filter[saldo_cartera_c][eq]=0";
      }else if (filtroFactura.estado == "Cartera"){
        filtro += "&filter[saldo_cartera_c][gt]=0"; //[gt]: mayor
      }      
    }

    if(filtroFactura.fecha_fin != "") {
      filtro += "&filter[due_date][lt]=" + filtroFactura.fecha_fin;
    }

    if(filtroFactura.fecha_inicio != "") {
      filtro += "&filter[due_date][gt]=" + filtroFactura.fecha_inicio;
    }
    
    const url = this.apiEndpoint + 'module/AOS_Invoices?sort=-due_date&page[size]=' + pagSize + '&page[number]=' + pagConsulta + filtro;
    return this.http.get(url).toPromise();
  }

  obtenerListaPreciosCliente(idCliente: string){
    //const url = this.apiEndpoint + 'module/AOS_Invoices?sort=-date_modified&page[size]=' + pagSize + '&page[number]=' + pagConsulta;
    //return this.http.get(url).toPromise();
  }

  consultarProductosListaPrecios(listaPrecio: string, pagConsulta: number, pagSize: number){
    //listaPrecio = "4fc60b63-9c7c-a069-f9bf-5f9349e1b9ed";
    const url = this.apiEndpoint + 'module/AOS_Products?filter[aos_product_category_id][eq]=' + listaPrecio + '&filter[price][neq]=0&page[size]=' + pagSize + '&page[number]=' + pagConsulta;
    return this.http.get(url).toPromise();
  }

  getProductosLista(data){
    let header = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    let url = this.url_novili + "getProductosLista";

    return this.http.post(url, data , header);
  }

  async consultarProductos(pagConsulta: number, pagSize: number){
    const url = this.apiEndpoint + 'module/AOS_Products?page[size]=' + pagSize + '&page[number]=' + pagConsulta;
    return this.http.get(url).toPromise();
  }
  

  /******************Funciones genericas*****************/
  crearRegistro(tipoRegistro: string, atributos: Object) {
    const url = this.apiEndpoint + 'module';
    atributos["assigned_user_id"] = window.sessionStorage.getItem("UserID");
    const body = { 
      "data": {
        "type": tipoRegistro,
        "attributes": atributos,
      } 
    };
    return this.http.post(url, body);
  }

  actualizarRegistro(tipoRegistro: string, id: string, atributos: Object) {
    const url = this.apiEndpoint + 'module';
    const body = { 
      "data": {
        "type": tipoRegistro,
        "id": id,
        "attributes": atributos,
      } 
    };
    return this.http.patch(url, body);
  }

  async consultarRegistroID(tipoRegistro: string, idRegistro: string) {
    const url = this.apiEndpoint + 'module/' + tipoRegistro + '/' + idRegistro;
    return this.http.get(url).toPromise();
  }

  /*Consulta de los módulos Calls, Tasks y Meetings*/
  consultarActividadesPendientesDiarias(actividad: string){
    const fechaAnterior: Date = new Date();
    const fechaSiguiente: Date = new Date();
    //fechaAnterior.setDate(fechaAnterior.getDate() - 1);
    fechaSiguiente.setDate(fechaSiguiente.getDate() + 1);
    
    const filtro = 'filter[date_start][gt]=' + moment(fechaAnterior).format('YYYYMMDD').toString() + 
      '&filter[date_start][lt]=' + moment(fechaSiguiente).format('YYYYMMDD').toString();
    const url = this.apiEndpoint + 'module/' + actividad + '?sort=date_start&' + filtro;
    return this.http.get(url);
  }

/****************************** */

  async consultarContactosPorComercial(pagConsulta: number, pagSize: number){ //TODO: Recibir los filtros desde el botón de filtro
    //const url = this.apiEndpoint + 'module/Accounts?filter[operator]=and&filter[assigned_user_id][eq]=' + window.sessionStorage.getItem('UserID');
    const url = this.apiEndpoint + 'module/Contacts?sort=-date_modified&page[size]=' + pagSize + '&page[number]=' + pagConsulta;
    return this.http.get(url).toPromise();
  }

  /*consultarNegociosPorRelacion(tipoRegistro: string, idRegistro: string): Negocio[] {

    const url = this.apiEndpoint + 'module/' + tipoRegistro + '/' + idRegistro + '/relationships/opportunities?sort=-date_closed';
    var negocios: Negocio[] = [];
    //var negocio: Object = new Object();
    this.http.get(url).subscribe(async (response) => {
      for(var index in response['data']){
        const id = response['data'][index]['id'];
        //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
        const negocio = await this.consultarRegistroID('Opportunities', id);
        negocios.push(
          new Negocio(
            negocio['data']['id'],
            negocio['data']['attributes']['name'],
            negocio['data']['attributes']['opportunity_type'],
            negocio['data']['attributes']['lead_source'],
            negocio['data']['attributes']['description'],
            negocio['data']['attributes']['amount'],
            negocio['data']['attributes']['sales_stage'],
            moment(negocio['data']['attributes']['date_closed']).toString(),
            moment(negocio['data']['attributes']['date_entered']).toDate(),
            moment(negocio['data']['attributes']['date_modified']).toDate(),
            negocio['data']['attributes']['account_name'],
            negocio['data']['attributes']['account_id']
          )
        );
      }
    });
    return negocios;
  }*/

  async consultarContactosActividad(tipoActividad: string, idActividad: string) {
    const url = this.apiEndpoint + 'module/' + tipoActividad + '/' + idActividad + '/relationships/contacts';
    return this.http.get(url).toPromise();
  }
  
  crearContacto(contacto: Contacto) {
    const tipoRegistro = "Contacts";
    const filtro = "filter[phone_mobile][eq]=" + contacto.celular;// + "&filter[email1][eq]=" + contacto.email; // + "&page[size]=10";
    const atributos = this.obtenerAtributosContacto(contacto);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarContacto(contacto: Contacto) {
    const atributos = this.obtenerAtributosContacto(contacto);
    return this.actualizarRegistro("Contacts", contacto.id, atributos);
  }

  obtenerAtributosContacto(contacto: Contacto) {
    const atributos = {
      "first_name": contacto.nombre,
      "last_name": contacto.apellido,
      //"tipo_documento_c": contacto.tipo_documento,
      //"numero_documento_c": contacto.numero_documento,
      "title": contacto.puesto_trabajo,
      "description": contacto.descripcion,
      "primary_address_street": contacto.direccion,
      "primary_address_city": contacto.ciudad,
      "primary_address_state": contacto.departamento,
      "primary_address_country": contacto.pais,
      "phone_mobile": contacto.celular,
      //"phone_office": contacto.telefono,
      "email1": contacto.email,
    };
    return atributos;
  }




  

  crearTarea(tarea: Tarea) {
    const tipoRegistro = "Tasks";
    const atributos = this.obtenerAtributosTarea(tarea);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarTarea(tarea: Tarea) {
    const tipoRegistro = "Tasks";
    const atributos = this.obtenerAtributosTarea(tarea);
    return this.actualizarRegistro(tipoRegistro, tarea.id, atributos);
  }

  obtenerAtributosTarea(tarea: Tarea) {
    var fechaInicio = new Date(tarea.fecha_hora_inicio);
    var fechaRealizacion = new Date(tarea.fecha_realizacion);
    var fechaVencimiento = new Date(tarea.fecha_hora_vencimiento);

    const atributos = {
      "name": tarea.asunto,
      "status": tarea.estado,
      "date_start": moment(fechaInicio.setHours(fechaInicio.getHours() + 5)).format('YYYY-MM-DD HH:mm:ss').toString(),
      "date_due": moment(fechaVencimiento.setHours(fechaVencimiento.getHours() + 5)).format('YYYY-MM-DD HH:mm:ss').toString(),
      "fecha_realizacion_c": moment(fechaRealizacion.setHours(fechaRealizacion.getHours() + 5)).format('YYYY-MM-DD HH:mm:ss').toString(),
      "priority": tarea.prioridad,
      "description": tarea.descripcion,
      "parent_type": tarea.parent_type,
      "parent_name": tarea.parent_name,
      "parent_id": tarea.parent_id,
      "contact_name": tarea.contact_name,
      "contact_id": tarea.contact_id,
      "tipo_tarea_c": tarea.tipo
    };
    return atributos;
  }


  
  crearReunion(reunion: Reunion) {
    const tipoRegistro = "Meetings";
    const atributos = this.obtenerAtributosReunion(reunion);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarReunion(reunion: Reunion) {
    const tipoRegistro = "Meetings";
    const atributos = this.obtenerAtributosReunion(reunion);
    return this.actualizarRegistro(tipoRegistro, reunion.id, atributos);
  }

  obtenerAtributosReunion(reunion: Reunion) {
    var fecha = new Date(reunion.fecha_hora_inicio);
    
    const atributos = {
      "name": reunion.asunto,
      "status": reunion.estado,
      "tipo_cita_c": reunion.tipo_cita,
      "date_start": moment(fecha.setHours(fecha.getHours() + 5)).format('YYYY-MM-DD HH:mm:ss').toString(),
      "duration_hours": reunion.duracion_horas,
      "duration_minutes": reunion.duracion_minutos,
      "location": reunion.lugar,
      "description": reunion.descripcion,
      "parent_type": reunion.parent_type,
      "parent_name": reunion.parent_name,
      "parent_id": reunion.parent_id,
      "contact_name": reunion.contact_name,
      "contact_id": reunion.contact_id,
      "jjwg_maps_lat_c": reunion.latitud,
      "jjwg_maps_lng_c": reunion.longitud
    };
    return atributos;
  }

  eliminarRegistro(tipoRegistro: string, id: string) {
    const url = this.apiEndpoint + 'module/' + tipoRegistro + '/' + id;
    return this.http.delete(url);
  }

  async crearActualizarRegistro(tipoRegistro: string, filtros: string, atributos: Object) {
    const response = await this.consultarRegistroFiltro(tipoRegistro, filtros);
    console.log(response['data']);
    if (response['data'].length != 0) {
      const idRegistro = response['data']['0']['id'];
      return this.actualizarRegistro(tipoRegistro, idRegistro, atributos).toPromise();
    }
    else {
      return this.crearRegistro(tipoRegistro, atributos).toPromise();
    }
  }

  

  async consultarRegistroFiltro(tipoRegistro: string, filtros: string) {
    const url = this.apiEndpoint + 'module/' + tipoRegistro + '?filter[operator]=and&' + filtros;
    return this.http.get(url).toPromise();
  }

  

  crearRelacion(tipoRegistro1: string, idRegistro1: string, tipoRegistro2: string, idRegistro2: string) {
    console.log("contact");
    console.log(idRegistro2);
    const url = this.apiEndpoint + 'module/' + tipoRegistro1 + '/' + idRegistro1 + '/relationships';
    const body = { 
      "data": {
        "type": tipoRegistro2,
        "id": idRegistro2
      } 
    };

    console.log("body");
    console.log(body);
    return this.http.post(url, body);
  }

  consultarReunionesPorComercial(){
    const url = this.apiEndpoint + 'module/Meetings';
    return this.http.get(url);
  }

  consultarTareasPorComercial(paginaConsulta: number){
    const url = this.apiEndpoint + 'module/Tasks?sort=-date_modified&page[size]=20&page[number]=' + paginaConsulta;
    return this.http.get(url);
  }

  async consultarNegociosPorComercial(etapaVentas: string, paginaConsulta: number){
    const url = this.apiEndpoint + 'module/Opportunities?filter[sales_stage][eq]='+etapaVentas+'&sort=-date_modified&page[size]=20&page[number]=' + paginaConsulta;
    return this.http.get(url).toPromise();
  }

  async consultarReunionesPorFecha(fecha: string, paginaConsulta: number, pagSize: number){
    const filtro = 'filter[date_start][gte]=' + fecha + '&page[size]=' + pagSize + '&page[number]=' + paginaConsulta;
    const url = this.apiEndpoint + 'module/Meetings?' + filtro;
    return this.http.get(url).toPromise();
  }

  agregarUsuarioActividad(actividad: string, idActividad: string){
    var parametros = {
      "valores": "'" + idActividad +  "', '" + window.sessionStorage.getItem("UserID") + "', 1, 'accept', 0",
      "database": "novili_suitecrm"
    }
    switch (actividad) {
      case "Meetings":
        parametros['tabla'] = "meetings_users";
        parametros['campos'] = "meeting_id, user_id, required, accept_status, deleted";
        break;
      case "Calls":
        parametros['tabla'] = "calls_users";
        parametros['campos'] = "call_id, user_id, required, accept_status, deleted";
        break;
      default:
        return;
    }
    const cabecera = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    
    return this.http.post(this.apiEnpointBack + "agregarRegistroMySQL", parametros, cabecera);    
  }

  filtrarNegocios(etapaNegocio: string, nroPagina: number, filtroNegocio){ 
    var filtro = "";
    
    if (filtroNegocio.nombre != undefined) {
    filtro = filtro + "&filter[name][eq]=" + filtroNegocio.nombre;
    }
    if (filtroNegocio.fechaCierre != undefined) {
    filtro = filtro + "&filter[date_closed][eq]=" + filtroNegocio.fechaCierre;
    }
    if (filtroNegocio.etapa != undefined) {
    filtro = filtro + "&filter[sales_stage][eq]=" + filtroNegocio.etapa;
    }
    if (filtroNegocio.monto != undefined) {
    filtro = filtro + "&filter[amount][eq]=" + filtroNegocio.monto;
    }
    if (filtroNegocio.tipo != undefined) {
    filtro = filtro + "&filter[opportunity_type][eq]=" + filtroNegocio.tipo;
    }
    
    const url = this.apiEndpoint + 'module/Opportunities?sort=-date_modified' + filtro;
    return this.http.get(url);
  }

  

  async filtrarTareas(nroPagina: number, filtroTarea)
  { 
    var filtro = "&filter[operator]=and";
    
    if (filtroTarea.estado != "") {
    filtro = filtro + "&filter[status][eq]=" + filtroTarea.estado;
    }
    if (filtroTarea.prioridad != "") {
    filtro = filtro + "&filter[priority][eq]=" + filtroTarea.prioridad;
    }
    if (filtroTarea.fechaInicio != "") {
    filtro = filtro + "&filter[date_start][eq]=" + filtroTarea.fechaInicio;
    }
    if (filtroTarea.fechaVencimiento != "") {
    filtro = filtro + "&filter[date_due][eq]=" + filtroTarea.fechaVencimiento;
    }
    const url = this.apiEndpoint + 'module/Tasks?sort=-date_modified' + filtro + '&page[size]=20&page[number]=' + nroPagina;
    return this.http.get(url).toPromise();
  }

  async filtrarTareasPorCliente(filtroTarea): Promise<Tarea[]> {
    const url = this.apiEndpoint + 'module/Accounts/' + filtroTarea.cliente + '/relationships/tasks';
    var tareas: Tarea[] = [];
    const response = await this.http.get(url).toPromise();
    for(var index in response['data']){
      const id = response['data'][index]['id'];
      const tarea = await this.consultarRegistroID('Tasks', id);
      
      var agregarTarea = true;
      if (filtroTarea.estado != "" && filtroTarea.estado != tarea['data']['attributes']['status']){
        agregarTarea = false;
      }
      if (filtroTarea.prioridad != "" && filtroTarea.prioridad != tarea['data']['attributes']['priority']){
        agregarTarea = false;
      }
      if (filtroTarea.fechaInicio != "" && filtroTarea.fechaInicio != tarea['data']['attributes']['date_start']){
        agregarTarea = false;
      }
      if (filtroTarea.fechaVencimiento != "" && filtroTarea.fechaVencimiento != tarea['data']['attributes']['date_due']){
        agregarTarea = false;
      }

      if(agregarTarea) {
        tareas.push(
          new Tarea(
            tarea['data']['id'],
            tarea['data']['attributes']['name'],
            tarea['data']['attributes']['status'],
            tarea['data']['attributes']['tipo_tarea_c'],
            tarea['data']['attributes']['date_start'],
            tarea['data']['attributes']['date_due'],
            tarea['data']['attributes']['date_due'],
            tarea['data']['attributes']['parent_type'],
            tarea['data']['attributes']['parent_name'],
            tarea['data']['attributes']['parent_id'],
            tarea['data']['attributes']['contact_name'],
            tarea['data']['attributes']['contact_id'],
            tarea['data']['attributes']['priority'],
            tarea['data']['attributes']['description'],
            ""
          )
        );
      }
    }
    return tareas;
  }

  async consultarPedidos(pagConsulta: number, pagSize: number){
    const url = this.apiEndpoint + 'module/AOS_Quotes?sort=-date_entered&page[size]=' + pagSize + '&page[number]=' + pagConsulta;
    return this.http.get(url).toPromise();
  }
  
  async crearGrupoItems(grupoItems){
    const tipoRegistro = "AOS_Line_Item_Groups";
    const filtro = "filter[parent_type][eq]=AOS_Quotes" + "&filter[parent_id][eq]=" + grupoItems.id_relacion;
    const atributos = this.obtenerAtributosGrupoItems(grupoItems);
    return this.crearActualizarRegistro(tipoRegistro, filtro, atributos);
  }

  obtenerAtributosGrupoItems(grupoItems){
    const atributos = {
      "parent_type": grupoItems.tipo_relacion,
      "parent_id": grupoItems.id_relacion,
      "total_amt": grupoItems.total,
      "discount_amount": grupoItems.descuento,
      "subtotal_amount": grupoItems.subtotal,
      "tax_amount": grupoItems.iva,
      "total_amount": grupoItems.gran_total
    };
    return atributos;
  }

  async crearProductoPedido(productoComprado: ProductoComprado, idPedido: string, idGrupoItems: string){
    const url = this.apiEndpoint + 'module/AOS_Products?filter[name][eq]=' + productoComprado.nombre;
    const response = await this.http.get(url).toPromise();
    const tipoRegistro = "AOS_Products_Quotes";
    const filtro = "filter[parent_type][eq]=AOS_Quotes" + "&filter[parent_id][eq]=" + idPedido + "&filter[group_id][eq]=" + idGrupoItems + "&filter[name][eq]=" + productoComprado.nombre;
    const atributos = this.obtenerAtributosProductoPedido(productoComprado, idPedido, idGrupoItems, response['data'][0]['id']);
    return this.crearActualizarRegistro(tipoRegistro, filtro, atributos);
  }

  obtenerAtributosProductoPedido(productoComprado: ProductoComprado, idPedido: string, idGrupoItems: string, idProducto: string){
    const atributos = {
      "parent_type": "AOS_Quotes",
      "parent_id": idPedido,
      "group_id": idGrupoItems,
      "name": productoComprado.nombre,
      "product_id": idProducto,
      //"part_number": response['data']['attributes']['part_number'],
      "product_unit_price": productoComprado.precio_unitario,
      "product_list_price": productoComprado.precio_lista,
      "product_total_price": productoComprado.precio_total,
      "product_qty": productoComprado.cantidad,
      "discount": "Percentage",
      "product_discount": productoComprado.porcentaje_descuento,
      "product_discount_amount": productoComprado.cantidad_descuento,
      "vat": productoComprado.porcentaje_iva,
      "vat_amt": productoComprado.cantidad_iva,
      "part_number":productoComprado.cod_producto,
      "ico_c": productoComprado.ico,
      "advaloren_c": productoComprado.ad_valorem,
      "description": productoComprado.descripcion
    };

    return atributos;
  }

  

  

  /*********PARA ELIMINAR********************************* 
  crearNegocio(negocio: Negocio) {
      const tipoRegistro = "Opportunities";
      const atributos = this.obtenerAtributosNegocio(negocio);
      return this.crearRegistro(tipoRegistro, atributos);
    }
  
    editarNegocio(negocio: Negocio) {
      const tipoRegistro = "Opportunities";
      const atributos = this.obtenerAtributosNegocio(negocio);
      return this.actualizarRegistro(tipoRegistro, negocio.id, atributos);
    }
  
    obtenerAtributosNegocio(negocio: Negocio) {
      const atributos = {
        "name": negocio.nombre,
        "opportunity_type": negocio.tipo_negocio,
        "description": negocio.descripcion,
        "account_name": negocio.nombre_cliente,
        "account_id": negocio.id_cliente,
        "amount": negocio.monto,
        "sales_stage": negocio.etapa_venta,
        "date_closed": moment(negocio.fecha_cierre).format('YYYY-MM-DD').toString()
      };
      return atributos;
    }
    
    async filtrarNegociosPorCliente(etapaNegocio: string, nroPagina: number, filtroNegocio): Promise<Negocio[]> {
      const url = this.apiEndpoint + 'module/Accounts/' + filtroNegocio.cliente + '/relationships/opportunities';
      var negocios: Negocio[] = [];
      const response =  await this.http.get(url).toPromise();
      for(var index in response['data']){
        const id = response['data'][index]['id'];
        const negocio = await this.consultarRegistroID('Opportunities', id);
        var agregarNegocio = true;
        if (filtroNegocio.nombre != undefined && filtroNegocio.nombre != negocio['data']['attributes']['name']){
          agregarNegocio = false;
        }
        if (filtroNegocio.fechaCierre != undefined && filtroNegocio.fechaCierre != negocio['data']['attributes']['date_closed']){
          agregarNegocio = false;
        }
        if (filtroNegocio.etapa != undefined && filtroNegocio.etapa != negocio['data']['attributes']['sales_stage']){
          agregarNegocio = false;
        }
        if (filtroNegocio.monto != undefined && filtroNegocio.monto != negocio['data']['attributes']['amount']){
          agregarNegocio = false;
        }
        if (filtroNegocio.tipo != undefined && filtroNegocio.tipo != negocio['data']['attributes']['opportunity_type']){
          agregarNegocio = false;
        }
  
        if(agregarNegocio) {
          negocios.push(
            new Negocio(
              negocio['data']['id'],
              negocio['data']['attributes']['name'],
              negocio['data']['attributes']['opportunity_type'],
              negocio['data']['attributes']['lead_source'],
              negocio['data']['attributes']['description'],
              negocio['data']['attributes']['amount'],
              negocio['data']['attributes']['sales_stage'],
              moment(negocio['data']['attributes']['date_closed']).toString(),
              moment(negocio['data']['attributes']['date_entered']).toDate(),
              moment(negocio['data']['attributes']['date_modified']).toDate(),
              negocio['data']['attributes']['account_name'],
              negocio['data']['attributes']['account_id']
            )
          );
        }
      }
      console.log(negocios);
      return negocios;
    }
  
    /*********FIN ELIMINAR********************************* */
}
