import { TokenService } from './../login/token.service';
import { CrmService } from 'src/app/api/crm.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Cliente } from '../models/cliente.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-modal-filtro',
  templateUrl: './modal-filtro.component.html',
  styleUrls: ['./modal-filtro.component.scss'],
})
export class ModalFiltroComponent implements OnInit {
  @Input() filtro;
  @Input() clientes;
  @Input() accion: string;

  public subscription: Subscription;
  
  //Listas globales
  public tiposCliente = JSON.parse(this.tokenService.getParametros())['Accounts']['tipo'];
  public tiposDocumento = JSON.parse(this.tokenService.getParametros())['Accounts']['tipoDocumento'];
  public canales = JSON.parse(this.tokenService.getParametros())['Accounts']['canal'];

  //public tiposNegocio = JSON.parse(this.tokenService.getParametros())['tiposNegocio'];
  //public etapasVenta = JSON.parse(this.tokenService.getParametros())['etapasVentaNegocio'];
  //public tiposLlamada = JSON.parse(this.tokenService.getParametros())['tiposLlamada'];
  //public estadosLlamada = JSON.parse(this.tokenService.getParametros())['estadosLlamada'];
  //public estadosReunion = JSON.parse(this.tokenService.getParametros())['estadosReunion'];
  //public prioridadesTarea = JSON.parse(this.tokenService.getParametros())['prioridadesTarea'];
  //public estadosTarea = JSON.parse(this.tokenService.getParametros())['estadosTarea'];

  constructor(public modalCtrl: ModalController,
              private tokenService: TokenService,
              private CrmService: CrmService) { }

  ngOnInit() {
    this.clientes = JSON.parse(localStorage.getItem("NombresClientes"));
    console.log(this.clientes);}

  cerrarModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  actualizarNombre(nombre: string) {
    if(this.accion == 'filtrarNegocios'){
      this.filtro.nombre = nombre;
    }
    else {
      this.filtro.nombre = nombre;
    }
  }

  actualizarTelefono(telefono: string) {
    this.filtro.telefono = telefono;
  }

  actualizarEmail(email: string) {
    this.filtro.correo = email;
  }

  actualizarCiudad(ciudad: string) {
    this.filtro.ciudad = ciudad;
  }

  actualizarDepartamento(departamento: string) {
    this.filtro.departamento = departamento;
  }

  actualizarPais(pais: string) {
    this.filtro.pais = pais;
  }

  actualizarTipoCuenta(tipoCuenta: string) {
    this.filtro.tipo = tipoCuenta;
  }

  async filtrarClientes() {
    this.cerrarModal();  
  }

  actualizarFechaCierre(fechaCierre: string) {
    this.filtro.fechaCierre = fechaCierre;
  }

  actualizarMonto(monto: number) {
    this.filtro.monto = monto;
  }

  actualizarTipo(tipo: string) {
    this.filtro.tipo = tipo;
  }

  actualizarEtapa(etapa: string) {
    this.filtro.etapa = etapa;
  }

  filtrarNegocios() {
    this.cerrarModal();
  }

  actualizarEstado(estado: string) {
    this.filtro.estado = estado;
  }

  actualizarFechaInicio(fechaVencimiento: string) {
    this.filtro.fechaInicio = fechaVencimiento;
  }
  
  actualizarFechaVencimiento(fechaVencimiento: string) {
    this.filtro.fechaVencimiento = fechaVencimiento;
  }

  actualizarPrioridad(prioridad: string) {
    this.filtro.prioridad = prioridad;
  }

  clienteChange(event: {
    component: IonicSelectableComponent,
    value: any}) {
    this.actualizarIdCliente(event.value.id);
  }

 /* buscarCliente(cliente){
   
      //this.actualizarNombre(event.value);
      console.log(cliente);
      this.clientes = this.CrmService.consultarNombresClientes(cliente);
      //this.clientes = JSON.parse(localStorage.getItem("NombresClientes"));
      console.log(this.clientes);
  }*/

  changeNombre(event: {
    component: IonicSelectableComponent,
    value: any}) {
      this.actualizarID(event.value.id);
  }

  buscarCliente(event: {
    component: IonicSelectableComponent,
    text: string 
    }) {
    
      let text = event.text.trim().toLowerCase();   
      event.component.startSearch();
        
      if (!text) {
        // Close any running subscription. 
        if (this.subscription) { 
        this.subscription.unsubscribe()
      }
      
      event.component.items = []; 
      event.component.endSearch();
      
      return;  
    }
 
    this.subscription = this.CrmService.consultarNombresClientes(text).subscribe(response => { 
    // Subscription will be closed when unsubscribed manually.
      if (this.subscription.closed) {
        return;
      }
      
      this.clientes = [];
      
      for (const index in response) {    
        this.clientes.push(response[index]);
      }
      
      event.component.items = this.clientes;
      event.component.endSearch();
    }); 
  }

  buscarPotencial(event: {
    component: IonicSelectableComponent,
    text: string 
    }) {
    
      let text = event.text.trim().toLowerCase();   
      event.component.startSearch();
        
      if (!text) {
        // Close any running subscription. 
        if (this.subscription) { 
        this.subscription.unsubscribe()
      }
      
      event.component.items = []; 
      event.component.endSearch();
      
      return;  
    }
 
    this.subscription = this.CrmService.consultarNombresPotenciales(text).subscribe(response => { 
    // Subscription will be closed when unsubscribed manually.
      if (this.subscription.closed) {
        return;
      }
      
      this.clientes = [];
      
      for (const index in response) {    
        this.clientes.push(response[index]);
      }
      
      event.component.items = this.clientes;
      event.component.endSearch();
    }); 
  }

  actualizarID(id: string) {
    this.filtro.id = id;
  }

  actualizarCanal(canal: string) {
    this.filtro.canal = canal;
  }

  actualizarTipoDocumento(tipoDocumento: string) {
    this.filtro.tipo_documento = tipoDocumento;
  }

  actualizarDocumento(numero_documento: string) {
    this.filtro.numero_documento = numero_documento;
  }

  actualizarIdCliente(idCliente: string) {
    this.filtro.cliente = idCliente;
  }

  async filtrarTareas() {
    this.cerrarModal();
  }

}
