import { Contacto } from './contacto.model';

export class Tarea {
    constructor(
        public id: string,
        public asunto: string, 
        public estado: string,
        public tipo: string,
        public fecha_hora_inicio: string, 
        public fecha_hora_vencimiento: string, 
        public fecha_realizacion: string,
        public parent_type: string,
        public parent_name: string,
        public parent_id: string,
        public contact_name: string,
        public contact_id: string,
        public prioridad: string,
        public descripcion: string,
        public contactos: string
    ) {}
}
